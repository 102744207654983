<template>
	<div>
		<b-row>
			<b-col lg="6" md="6" sm="6" class="heading-title">
				<h4>{{ this.venue_booking_details.type=='draft' ? "Draft" :"Quoation" }}  Summary </h4>
				<b-breadcrumb class="p-0 m-0" id="breadcrumb-heading">
					<b-breadcrumb-item to="/dashboard" custom> Home </b-breadcrumb-item>
					<b-breadcrumb-item to="/drafts-booking"> Draft Booking</b-breadcrumb-item>
					<b-breadcrumb-item active>{{ this.venue_booking_details.type=='draft' ? "Draft" :"Quoation" }}  Summary </b-breadcrumb-item>
				</b-breadcrumb>
			</b-col>
			<b-col lg="6" md="6" sm="6" class="btns-header"> </b-col>
		</b-row>
		<br>
		<b-row>
			<b-col lg="12" md="12" sm="12" class="heading-title" ref="msgContainer">
				<b-button  @click="$router.go(-1)"  class=" btn-venuecolor btn-sm mb-2 mt-1">
					<i class="mdi mdi-arrow-left "></i> Back
				</b-button>
&nbsp; &nbsp;
				<b-button class="btn btn-venuecolor btn-sm mb-2 mt-1" @click="editbooking"><i class="mdi mdi-pencil"></i> Edit Booking</b-button>
				
				
				<b-card class="mb-4 w-100">
					<b-row>
						<b-col lg="12" md="12" sm="12" class="heading-title" ref="msgContainer">
							
							<h3 class="text-center">  {{ this.venue_booking_details.type=='draft' ? "Draft" :"Quoation" }}  Summary </h3>
							<div class="d-flex justify-content-between">
								
								<div> 
									Order date : <span class="text-danger fs-4"> {{date_format(this.venue_booking_details.booked_on)}}</span>
								</div>
								<div class="mt-2"> 
									
									<div>Form ID. <span class="text-danger fs-4">{{ this.venue_booking_details.auto_increment }}</span></div>
									
								</div>
							</div>
							<hr>
							
							<b-row>
								<div class="col-lg-12">
									<table class="w-100">
										<tbody>
											<tr>
												<td><b>Venue Name</b></td>
												<td class="px-3">:</td>
												<td>{{ this.venue_booking_details.child_venue_name }}</td>
												
												<td class="text-left"><b>Event Date</b></td>
												<td class="px-3">:</td>
												<td class="text-left">{{ date_format(this.venue_booking_details.from_date) }}</td>
											</tr>
											<tr>
												<td><b>Shift</b></td>
												<td class="px-3">:</td>
												<td>{{ this.booked_shift_type }} Shift</td>
												<td><b>Timings</b></td>
												<td class="px-3">:</td>
												<td>{{ convert_24_12hour(this.venue_booking_details.from_time) }} to {{ convert_24_12hour(this.venue_booking_details.to_time) }}</td>
												
											</tr>
											<tr>
												<td><b>Event type</b></td>
												<td class="px-3">:</td>
												<td><span v-if="this.venue_booking_details.event_name != ''">{{ this.venue_booking_details.event_name }}</span></td>
												
												<td><b>Guest capacity </b></td>
												<td class="px-3">:</td>
												<td>{{ this.venue_booking_details.guest_capacity }}</td>
												
												
											</tr>
											
										</tbody>
									</table>
								</div>
								
							</b-row>
							<hr>	
							<b-row>	
								
								<div class="col-lg-8">
						<!-- 	<hr  v-if=" venue_booking_details.booking_for_name!=null || venue_booking_details.booking_for_relationship!=null 
							|| venue_booking_details.booking_for_mobile_no!=null || venue_booking_details.billing_bride_name!=null || 
							billing_first_name!=null || billing_email!=null ||
							billing_phone!=null || billing_address!=null || 
							Alternative_number!=null "> -->
							
						
									<h4 v-if=" venue_booking_details.booking_for_name!=null || venue_booking_details.booking_for_relationship!=null 
									|| venue_booking_details.booking_for_mobile_no!=null || venue_booking_details.billing_bride_name!=null || 
									billing_first_name!=null || billing_email!=null ||
									billing_phone!=null || billing_address!=null || 
									Alternative_number!=null " > Customer details</h4>
									<table class="w-100">
										<tbody>
											
											<tr v-if="this.venue_booking_details.booking_for_self == 0"  style="background-color:#F5F5F5;">
												<td style="width: 180px;" v-if="this.venue_booking_details.booking_for_name !=null"><b> Representative name</b></td>
												<td class="px-3" v-if="this.venue_booking_details.booking_for_name !=null">:</td>
												<td v-if="this.venue_booking_details.booking_for_name !=null">{{ this.venue_booking_details.booking_for_name }}</td>
												
												<td style="width: 180px;" class="text-left" v-if="this.venue_booking_details.booking_for_relationship !=null"><b> Relationship </b></td>
												<td class="px-3" v-if="this.venue_booking_details.booking_for_relationship !=null">:</td>
												<td class="text-left" v-if="this.venue_booking_details.booking_for_relationship !=null">{{ this.venue_booking_details.booking_for_relationship }}</td>
												
											
											</tr>
											
											<tr v-if="this.venue_booking_details.booking_for_self == 0"  style="background-color:#F5F5F5;">
												<td style="width: 180px;" class="text-left" v-if="this.venue_booking_details.booking_for_mobile_no !=null"><b>Mobile no</b></td>
												<td class="px-3" v-if="this.venue_booking_details.booking_for_mobile_no !=null">:</td>
												<td class="text-left" v-if="this.venue_booking_details.booking_for_mobile_no !=null">{{ this.venue_booking_details.booking_for_mobile_no }}</td>
											</tr>

											<tr>
												<td style="width: 180px;" v-if="billing_first_name !=null"><b> Customer name</b></td>
												<td class="px-3"  v-if="billing_first_name !=null">:</td>
												<td  v-if="billing_first_name !=null">{{ billing_first_name }}</td>
												
												<td style="width: 180px;" class="text-left"  v-if="billing_email !=null"><b>Email</b></td>
												<td class="px-3"  v-if="billing_email !=null">:</td>
												<td class="text-left"  v-if="billing_email !=null">{{ billing_email }}</td>
												
											
											</tr>
											<tr>
												<td style="width: 180px;" class="text-left"  v-if="billing_phone !=null"><b>Phone</b></td>
												<td class="px-3"  v-if="billing_phone !=null">:</td>
												<td class="text-left"  v-if="billing_phone !=null">{{ billing_phone }}</td>

												
												<td style="width: 180px;" class="text-left" v-if="Alternative_number !=null"><b>Alternate Phone No.</b></td>
												<td class="px-3" v-if="Alternative_number !=null">:</td>
												<td class="text-left" v-if="Alternative_number !=null">{{ Alternative_number }}</td>
												
												
											</tr>
											<tr>
												

												<td style="width: 180px;" v-if="billing_address !=null"><b>Address</b></td>
												<td class="px-3"  v-if="billing_address !=null">:</td>
												<td  v-if="billing_address !=null">{{ billing_address }} </td>
											</tr>
											
										</tbody>
									</table>
								
							
							<span v-if="informations">
								<hr v-if=" informations.booking_sound!=null || informations.bride_mob!=null || informations.groom_mob!=null || informations.billing_bride_name!=null || informations.billing_bridegroom!=null || informations.billing_bridelocation!=null || informations.billing_bridegroomlocation!=null || informations.billing_caterer!=null || informations.booking_decorator!=null || informations.booking_music!=null">
								<h4 v-if=" informations.booking_sound!=null || informations.bride_mob!=null || informations.groom_mob!=null || informations.billing_bride_name!=null || informations.billing_bridegroom!=null || informations.billing_bridelocation!=null || informations.billing_bridegroomlocation!=null || informations.billing_caterer!=null || informations.booking_decorator!=null || informations.booking_music!=null"  > Information</h4>
							
										<table class="w-100" >
											<tbody>
												<tr>
													<td v-if="informations.billing_bride_name!=null"><b> Bride Name</b></td>
													<td v-if="informations.billing_bride_name!=null" class="px-3">:</td>
													<td v-if="informations.billing_bride_name!=null">{{ informations.billing_bride_name }}</td>
													
													<td v-if="informations.bride_mob!=null" class="text-left"><b> Bride Mobile no. </b></td>
													<td v-if="informations.bride_mob!=null" class="px-3">:</td>
													<td v-if="informations.bride_mob!=null" class="text-left">{{ informations.bride_mob }}</td>
													
													
													
													
												</tr>
												<tr>

													<td v-if="informations.billing_bridelocation!=null" class="text-left"><b> Bride City </b></td>
													<td v-if="informations.billing_bridelocation!=null" class="px-3">:</td>
													<td v-if="informations.billing_bridelocation!=null" class="text-left">{{ informations.billing_bridelocation }}</td>

													<td v-if="informations.billing_bridegroom!=null" class="text-left"><b> Groom Name</b></td>
													<td v-if="informations.billing_bridegroom!=null" class="px-3">:</td>
													<td v-if="informations.billing_bridegroom!=null" class="text-left">{{ informations.billing_bridegroom }}</td>
												</tr>
												<tr>	
													<td v-if="informations.groom_mob!=null" class="text-left"><b> Groom Mobile no.</b></td>
													<td v-if="informations.groom_mob!=null" class="px-3">:</td>
													<td v-if="informations.groom_mob!=null" class="text-left">{{ informations.groom_mob }}</td>
													
													
													<td v-if="informations.billing_bridegroomlocation!=null"><b>Groom City</b></td>
													<td v-if="informations.billing_bridegroomlocation!=null" class="px-3">:</td>
													<td v-if="informations.billing_bridegroomlocation!=null">{{ informations.billing_bridegroomlocation }} </td>
													
													
												</tr>
												
												<tr>
													<td v-if="informations.billing_caterer!=null" class="text-left"><b> Caterer Name </b></td>
													<td v-if="informations.billing_caterer!=null" class="px-3">:</td>
													<td v-if="informations.billing_caterer!=null" class="text-left">{{ informations.billing_caterer }}</td>
													
													<td v-if="informations.booking_decorator!=null" class="text-left"><b> Decorator Name </b></td>
													<td v-if="informations.booking_decorator!=null" class="px-3">:</td>
													<td v-if="informations.booking_decorator!=null" class="text-left">{{ informations.booking_decorator }}</td>
													
													
													
													
												</tr>
												<tr>
													<td v-if="informations.booking_music!=null"><b>Music person Name</b></td>
													<td v-if="informations.booking_music!=null" class="px-3">:</td>
													<td v-if="informations.booking_music!=null">{{ informations.booking_music }} </td>

													<td v-if="informations.booking_sound!=null"><b>Sound person Name</b></td>
													<td v-if="informations.booking_sound!=null" class="px-3">:</td>
													<td v-if="informations.booking_sound!=null">{{ informations.booking_sound }} </td>
												</tr>
												
												
											</tbody>
										</table>
								
							</span>
							<hr>
							
									<h4> Add-Ons</h4>
									
									<div class="row">
										
										
										<div class="col-md-3 col-xl-3 mb-4" v-for="(addon, index) in add_ons_details" :key="index">
											
											<div class="extradesign">
												<div class="card h-100">
													<div class="bg-label-primary rounded-3 text-center mb-0 pt-0">
														<img class="img-fluid fix-set custom-image-fluid" :src="addon.attachment ? baseUrl+'/'+JSON.parse(addon.attachment) : require('@/assets/images/addon.png') " alt="Card girl image" width="500">
													</div>
													<div class="mb-0 text-center">
														<span  v-if="addon.add_on_name.length<15" class="text-center">{{ addon.add_on_name }}</span>
														<span  class="text-center" v-else>{{ addon.add_on_name.substring(0,15)+".." }}</span> <br>
														<p><i :class="{'mdi':true,[curncy]:true}"></i>{{ Indian_numberWithCommas(addon.price) }} *{{ addon.qty }} <i :class="{'mdi':true,[curncy]:true}"></i>{{ Indian_numberWithCommas(addon.total) }} </p>
														
													</div>
													
												</div>
											</div>
										</div>
									</div>
								
								</div>
								<div class="col-md-4" style="border-left:1px solid mediumorchid">
									<h4 class="text-left p-3" style="border-bottom:5px solid mediumorchid"> Pricing Summary</h4>
											<ul class="custom-amount-summary_1">
												<li class="mt-2 mb-2">
													<div class="row mt-1 mb-1">
														<div class="col-md-8 text-left "> 
															Base Price
														</div>
														<div class="col-md-1 fw-bold text-right" > 
															<i :class="{'mdi':true,[curncy]:true,'left_amount_fix':true}"></i>
														</div>
														<div class="col-md-3 fw-bold text-right" > 
															{{  numberWithCommas( autocalculation(total_booking_price)) }}
														</div>
													</div>	
												</li>
												
												<li class="mt-2 mb-2">
													<div class="row mt-1 mb-1">
														<div class="col-md-7 text-left "> 
															<b>Add-on :</b> 
														</div>
														<!-- <div class="col-md-1 fw-bold text-right" > 
															<i :class="{'mdi':true,[curncy]:true,'left_amount_fix':true}"></i>
														</div>
														<div class="col-md-4 text-right"  > 
															{{ numberWithCommas(addOn_total) }}
														</div> -->
													</div>	
												</li>
												<li v-for="(addon, index) in add_ons_details" :key="index" class="mt-2 mb-2">
									<div class="row mt-1 mb-1">
										<div class="col-md-5 text-left "> 
											{{ addon.add_on_name }} 
										</div>
										<div class="col-md-3 fw-bold text-right" > 
												<i :class="{'mdi':true,[curncy]:true}"></i>{{ Indian_numberWithCommas(addon.price) }} *{{ addon.qty }}
												
											</div>
										<div class="col-md-1 fw-bold text-right" > 
											<i :class="{'mdi':true,[curncy]:true,'left_amount_fix':true}"></i>
													</div>
										<div class="col-md-3 text-right"  > 
											{{ Indian_numberWithCommas(addon.total) }}
										</div>
									</div>	

								</li>
								<li class="mt-2 mb-2" v-if="addOn_total">
									<div class="row mt-1 mb-1">
										<div class="col-md-8 text-left "> 
											
										</div>
										<div class="col-md-1 fw-bold text-right" > 
													<i :class="{'mdi':true,[curncy]:true,'left_amount_fix':true}"></i>
												</div>
												<div class="col-md-3 text-right" style="border-top: 1px solid black;" > 
													{{ Indian_numberWithCommas(addOn_total) }}
												</div>
									</div>	

								</li>
												<hr class="summary-hr">
												<li class="mt-2 mb-2">
													<div class="row mt-1 mb-1">
														<div class="col-md-8 text-left "> 
															<b> Total Base price</b> 
														</div>
														<div class="col-md-1 fw-bold text-right" > 
															<i :class="{'mdi':true,[curncy]:true,'left_amount_fix':true}"></i>
														</div>
														<div class="col-md-3 text-right" > 
															
															{{ numberWithCommas(add_sub_total) }}
															
														</div>
													</div>	
												</li>
												<li class="mt-2 mb-2">
													<div class="row mt-1 mb-1">
														<div class="col-md-8 text-left "> 
															Discount Applied
														</div>
														<div class="col-md-1 fw-bold text-right" > 
															<i :class="{'mdi':true,[curncy]:true,'left_amount_fix':true}"></i>
														</div>
														<div class="col-md-3  text-right"> 
															{{ numberWithCommas(this.venue_booking_details.applied_discount) }}
														</div>
													</div>	
												</li> 
												<hr class="summary-hr">
												<li class="mt-2 mb-2">
													<div class="row mt-1 mb-1">
														<div class="col-md-8 text-left "> 
															<b>Sub total</b>
														</div>
														<div class="col-md-1 fw-bold text-right" > 
															<i :class="{'mdi':true,[curncy]:true,'left_amount_fix':true}"></i>
														</div>
														<div  class="col-md-3 text-right"> 
															
															{{numberWithCommas( after_discount) }}
															
														</div>
													</div>	
												</li>
												<li class="mt-2 mb-2">
													<div class="row mt-1 mb-1">
														<div class="col-md-8 text-left "> 
															GST {{this.venue_booking_details.tax_percentage}} %
														</div>
														<div class="col-md-1 fw-bold text-right" > 
															<i :class="{'mdi':true,[curncy]:true,'left_amount_fix':true}"></i>
														</div>
														<div class="text-right col-md-3"> 
															{{ numberWithCommas(Math.round(afterTax)) }}
														</div>
													</div>	
												</li>
												<hr class="summary-hr">
												<li class="mt-2 mb-2">
													<div class="row mt-1 mb-1">
														<div class="col-md-8 text-left "> 
															Total
														</div>
														<div class="col-md-1 fw-bold text-right" > 
															<i :class="{'mdi':true,[curncy]:true,'left_amount_fix':true}"></i>
														</div>
														<div class="col-md-3 fw-bold fs-4  text-right"> 
															<span >
																{{ numberWithCommas(Math.round(total_amount)) }}
															</span>
														</div>
													</div>	
												</li>
												
												<li class="mt-2 mb-2">
													<div class="row mt-1 mb-1">
														<div class="col-md-8 text-left"> 
															Security Deposit
															<div><i class="fs-11 text-danger">(Refundable Deposit)</i></div>
														</div>
														<div class="col-md-1 fw-bold text-right" > 
															<i :class="{'mdi':true,[curncy]:true,'left_amount_fix':true}"></i>
														</div>
														<div class="col-md-3 fw-bold fs-4  text-right" > 
															<span class="">
																{{numberWithCommas(this.security_deposit)}}
															</span>
														</div>
													</div>	
												</li> 
												<hr class="summary-hr" >
												<li class="mt-2 mb-2">
													<div class="row mt-1 mb-1">
														<div class="col-md-8 text-left"> 
															Booking Total
														</div>
														<div class="col-md-1 fw-bold text-right" > 
															<i :class="{'mdi':true,[curncy]:true,'left_amount_fix':true}"></i>
														</div>
														<div class="col-md-3 fw-bold fs-4 text-success text-right" > 
															<span class="">
																{{ numberWithCommas(Math.round(full_total_amount))}}
															</span>
														</div>
													</div>	
												</li>
												
												
											</ul>

								<div v-if="this.special_request != null">
								<h5>Special Requests</h5>
								<p v-html="this.special_request"></p>
							</div>
								</div>
							
							</b-row>
							</b-col>
							</b-row>
							</b-card>
							</b-col>
							</b-row>
						
			</div>
</template>
<script>
	import axios from "axios";
	import helpers from "../../../mixins/helpers";
	export default {
		name: "BookingDetails",
		mixins: [helpers],
		data: () => ({
			title: "BookingDetails",
			vendor_name: "",
			baseUrl: "",
			vendor_id: "",
			venue_name: "",
			booked_on: "",
			from_date: "",
			to_date: "",
			selected_date: "",
			from_time: "",
			event_name: "",
			booked_shift_type: "",
			to_time: "",
			total_booking_price: "",
			child_venue_name: "",
			add_on: [],
			personal_phone: "",
			personal_email: "",
			customer_name: "",
			billing_first_name: "",
			billing_email: "",
			billing_address: "",
			billing_phone: "",
			cancel_time: "",
			cancel_date: "",
			cancel_reason: "",
			special_request:'',
			child_venue_id:'',
			Book_id:'',
			ref_id:'',
			informations:'',
			guest_capacity:'',
			addOn_total:0,
			add_sub_total:0,
			total_amount:0,
			full_total_amount:0,
			curncy:"",
			taxRequired:"",
			gst_percentage:"",
			security_deposit:'',
			permission_access:"",
			child_venue_arr:{
				addons_arr:[],
				attachment_path:'',
			},
			venue_booking_details:[],
			add_ons_details : [],
		}),
		
		methods: {
			Indian_numberWithCommas(x) {
				if(x)
				{
					return x.toString().split('.')[0].length > 3 ? x.toString().substring(0,x.toString().split('.')[0].length-3).replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + x.toString().substring(x.toString().split('.')[0].length-3): x.toString();
				} 
			},
			booking_details() {
				var booking_id = this.$route.params.id;
				axios
				.post("/api/get_draft_booking_detail", {
					booking_id: booking_id,
				})
				.then((resp) => {
					if (resp.data.status_code == 200) {
						if (resp.data.status) {
							
							var data = resp.data.booking_detail;
							this.venue_booking_details = data;
							
							
							if(data.booked_shift_type==1)
							{
								this.booked_shift_type = "Morning" ;
							}
                            else if(data.booked_shift_type==2)
							{
								this.booked_shift_type = "Afternoon" ;
							}
                            else if(data.booked_shift_type==3)
							{
								this.booked_shift_type = "Evening" ;
							}
                            this.billing_first_name = data.billing_first_name;
							this.billing_email = data.billing_email;
							this.billing_address = data.billing_address;
							this.billing_phone = data.billing_phone;
							this.cancel_time = data.cancel_time;
							this.cancel_date = data.cancel_date;
							this.cancel_reason = data.cancel_reason;
							this.special_request = data.special_request;
							this.child_venue_id = data.child_venue_id;
							
							this.security_deposit = data.security_deposit;
							this.gst_percentage = data.gst_percentage;
							
							this.Alternative_number = data.billing_alt_phone;
							
                            this.total_booking_price = data.total_booking_price;
							this.add_ons_details = data.addons;
							this.informations = JSON.parse(data.informations);
							
							
							
							if (data.add_on_details !== null) {
								this.add_on = JSON.parse(data.add_on_details);
								this.getChildVenueDetails();
							}
							} else {
							// this.$router.push('')
						}
						
					}
				});
			},
			goBack() {
				this.$router.push("/drafts-booking");
			},
			getChildVenueDetails(){
				axios.post("/api/VenueDetails", {
					child_venue_id: this.child_venue_id,
				})
				.then((resp) => {
					if (resp.data.status_code == 200 && resp.data.status) {
						this.child_venue_arr.addons_arr = resp.data.child_venue_details[0].add_ons;
						this.child_venue_arr.attachment_path = resp.data.child_venue_details[0].attachment_path;
					}
				});
			},
			editbooking()
			{
				var booking_id = this.$route.params.id;
				this.$router.push("/draft-edit-booking/"+booking_id);
			},
			date_format:function(date)
			{
				var d1 = new Date(date);
				var ms = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
				return d1.getDate() + '' + this.getDateOrdinal(d1) +' ' + ms[d1.getMonth()] + ' ' + d1.getFullYear();
			},
			getDateOrdinal(date) {
				let day = date.getDate();
				if (day > 3 && day < 21) return 'th';
				switch (day % 10) {
					case 1:  return "st";
					case 2:  return "nd";
					case 3:  return "rd";
					default: return "th";
				}
			},
			convert_24_12hour(timeString)
			{
				/* const [hourString, minute] = timeString.split(":");
				const hour = +hourString % 24;
				return (hour % 12 || 12) + ":" + minute + (hour < 12 ? "AM" : "PM"); */
				return timeString;
			},
			
			
			
			autocalculation(amount)
			{
				this.taxper =this.gst_percentage+'%';
				let add_amount=0;
				
				if(this.add_ons_details.length>0)
				{
					for(var i=0;i<this.add_ons_details.length;i++)
					{
						var tamt = this.add_ons_details[i].qty*this.add_ons_details[i].price;
						add_amount+=tamt;
					}
				}
				else
				{
					add_amount=0;
				}
				this.addOn_total=this.venue_booking_details.addon_total;
				this.add_sub_total=add_amount+amount;
				
				if(this.venue_booking_details.applied_discount != '' && this.venue_booking_details.applied_discount >= 0 && amount >= this.venue_booking_details.applied_discount){
					
					this.after_discount = parseInt(this.add_sub_total) - parseInt(this.venue_booking_details.applied_discount);
					
				}
                else
				{
					this.after_discount = parseInt(this.add_sub_total);
				}
				
				/* if(this.taxRequired=='yes')
					{
					this.afterTax=this.after_discount*18/100;
					}
					else
					{
					this.afterTax=0;
				} */
                this.afterTax=this.after_discount*this.gst_percentage/100;
				
				this.total_amount=this.after_discount+this.afterTax; //inclusive tax
				
				this.full_total_amount=parseInt(this.total_amount)+parseInt(this.security_deposit);
				
				return amount;
			},
			vendor_check()
			{
				axios.get("/api/get_profile").then((resp) => {
					if (resp.data.status_code == 200) {
						if (resp.data.status) {
							if(resp.data.users.gst_no==null)
							{
								this.taxRequired='no';
							}
							else
							{
								this.taxRequired='yes';
							}
						}
					}
				});
			},
			
			getProfile() {
				axios.get("/api/get_profile").then((resp) => {
					if (resp.data.status_code == 200) {
						this.curncy= resp.data.users.currencys;
					}
				});
			},
			access_permission()
			{
				axios
				.post("/api/access_permission_all").then((resp) => {
					for(var i=0; i< resp.data.length;i++)
					{
						if(resp.data[i].subname=='Create Booking' )
						{
							this.permission_access= resp.data[i];
						}
						
						
					}
				});
			},
		},
		mounted() {
			this.booking_details();
			this.vendor_check();
			this.getProfile();
			this.access_permission();
			document.documentElement.scrollTop = 0;
			this.baseUrl=process.env.VUE_APP_APIURL
		},
	};
</script>
<style scoped>
	.custom-image-fluid
	{
	max-height: 100px;
    -o-object-fit: cover;
    min-height: 50px;
    object-fit: cover;
	}
	.custom-amount-summary
	{
	list-style-type: none;
    margin-left: -40px;
	}
	.fs-12
	{
	font-size: 10px;
	}
	.extradesign
	{
	padding: 4px 4px 2px;
    box-shadow: 0px 0px 5px 0px;
    border-radius: 7px;
	margin-bottom: 11px;
	}
	
	.ml--3
	{
	margin-left: -3%;
	}.ml--7
	{
	margin-left: -7%;
	}
	.mt--3
	{
	margin-top: -3%;
	}
</style>